import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { ENVIRONMENT, Environment } from '@environment';
import { UsheruApi } from '@usheru-hff/shared/data-access-backend';
import { Country, News } from '@usheru-hff/shared/domain';

import { CountrySelectionService } from '../country-selection';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private readonly usheruApi = inject(UsheruApi);
  private readonly http = inject(HttpClient);
  private readonly env = inject(ENVIRONMENT);
  private readonly headers = this.usheruApi.getStandardHeader();
  private readonly countrySelectionService = inject(CountrySelectionService);

  getNews(pageNumber: number = 1, newsLimit: number): Observable<News[]> {
    return this.countrySelectionService.initialCountry$.pipe(
      switchMap(country => this.getNewsApi(pageNumber, newsLimit, country))
    );
  }

  getNewsItem(id: number): Observable<News> {
    if (this.isLocalEnv && this.placeholderData?.newsPosts) {
      const placeholder = typeof this.placeholderData.newsPosts === 'boolean' ? '1' : this.placeholderData.newsPosts;
      console.warn('Using placeholder data for news');
      return this.http.get<News>(`https://cdnstatic.usheru.com/assets/placeholder-data/newsPosts-${placeholder}.json`);
    }
    //
    return this.http.get<News>(`${this.env.api.url}/newsPosts/${id}`, {
      headers: this.headers
    });
  }

  private getNewsApi(pageNumber: number = 1, newsLimit: number, country?: Country) {
    let params = new HttpParams({
      fromObject: {
        postLimit: newsLimit,
        page: pageNumber
      }
    });

    if (country) params = params.set('country', country.id);
    return this.http.get<News[]>(this.env.api.newsPostsUrl(), {
      headers: this.headers,
      params
    });
  }
  get isLocalEnv() {
    return this.env.mode == 'develop' || this.env.mode == 'demo' || location.origin.indexOf('http://localhost') === 0;
  }

  get placeholderData() {
    return this.env?.placeholderData;
  }
}
